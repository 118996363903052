<template>
	<div id="landOrder">
		<page-land-rent-order :is-shop="0"></page-land-rent-order>
	</div>
</template>

<script>
import pageLandRentOrder from '@/components/layout/land/page-land-rent-order.vue'
import store from '@/store'
import { onBeforeRouteLeave } from 'vue-router'
export default{
	name:"LandOrder",
	components:{
		pageLandRentOrder
	},
	setup(){
		onBeforeRouteLeave((to,from,next)=>{
			let type = to.path == '/land/orderDetail' ? 1 : 2
			store.dispatch("setKeepPage",{name:from.name,type})
			next();
		})
	}
}
</script>

<style>
</style>
